<template>
  <layout :loading="loading">
    <h2 class="mb-4">Send feedback</h2>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <ckeditor v-model="form.content" :editor="editor" :config="editorConfig" @blur="saveFeedback()" @ready="getFeedbackFromLocal"></ckeditor>
            <div class="mt-3">
              <b-form-checkbox
                  v-model="form.isAnonymously"
                  class="custom-checkbox custom-control-right float-left mt-2"
                  checked
              >Do you want to send it anonymously?</b-form-checkbox>

              <b-button variant="primary" class="float-right" @click="handleSubmit()">Send</b-button>
              <b-button variant="light" class="float-right mr-2" @click="$router.go(-1)"> Back </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';


import {validateForm} from "../../../utils/validation";
import {convertObjectToFormData} from "../../../utils/converters";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Font from "@ckeditor/ckeditor5-font/src/font";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import {showSuccessToast} from "../../../utils/toasts";

/**
 * Editors component
 */
export default {
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      editor: ClassicEditor,
      form: {
        content: (this.currentFeedback || null),
        isAnonymously: false,
      },
      editorConfig: {
        plugins: [
          Alignment,
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Base64UploadAdapter,
          Image,
          ImageInsert,
          ImageStyle,
          ImageUpload,
          ListStyle,
          HorizontalLine,
          Indent,
          IndentBlock,
          Font,
          ImageToolbar,
          ImageResize,
        ],
        fontSize: {
          options: [
            8,
            10,
            12,
            14,
            16,
            18,
            20,
            22,
            24,
            26
          ]
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            '|',
            "numberedlist",
            "bulletedlist",
            "HorizontalLine",
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            '|',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
            '|',
            'undo',
            'redo',
          ]
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
          ],
          styles: [
            'full',
            'alignLeft',
            'alignRight'
          ]
        },
      }
    };
  },
  methods: {
    handleSubmit() {
      if (validateForm(this.form)) {
        this.$store.dispatch('feedback/sendFeedback', convertObjectToFormData(this.form)).then(() => {
          showSuccessToast('Your feedback is sent successfully!')
          this.clearForm();
          this.$router.push('/feedbacks')
        });
      }
    },
    clearForm() {
      this.form.content = '';
      this.form.isAnonymously = false;
      this.$store.commit('feedback/SET_CURRENT_FEEDBACK', this.form.content)
    },
    async saveFeedback() {
      await this.$store.commit('feedback/SET_CURRENT_FEEDBACK', this.form.content)
    },
    getFeedbackFromLocal () {
      if (this.currentFeedback) {
        this.form.content = this.currentFeedback
      }
    }
  },
  computed: {
    loading() {
      return this.$store.state.feedback.loading;
    },
    currentFeedback() {
      return this.$store.state.feedback.currentFeedback
    }
  }
};
</script>
